import {
  // IMG_BASEURL,
  BASEURL,
  IMG_BASEURL,
} from '@src/config';

export const hasStaring = (castCrewArray) => {
  let count = 0;
  castCrewArray &&
    castCrewArray.forEach((cast) => cast?.crew_title !== `Director` && count++);
  return count > 0;
};

export const hasDirector = (castCrewArray) => {
  let count = 0;
  castCrewArray &&
    castCrewArray.forEach((cast) => cast?.crew_title === `Director` && count++);
  return count > 0;
};

export const sortAlphabetically = (property) => {
  var sortOrder = 1;

  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  return function (a, b) {
    if (a.lang_id && b.lang_id) {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  };
};

export function getSeatIconUrl(icon, BASEURLConfig = BASEURL) {
  if (!icon) return '';
  if (icon?.startsWith('http')) return icon;

  let base = BASEURLConfig;
  if (icon.includes('S3/')) {
    base = IMG_BASEURL;
  }
  // if (!!IMG_BASEURL) base = IMG_BASEURL

  if (base?.endsWith('/')) base = base?.substring(0, base?.length - 1);

  let i = icon;
  if (!i?.startsWith('/')) i = `/${i}`;

  return `${base}${i}`;
}
