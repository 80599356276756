export const API_VERSION = "v1.0";
const HOST_URL = `mosaic.brij.tech`;
const LIVEBASEURL = `https://mosaic-api.brij.tech`;
const DEVBASEURL = `http://localhost:3800`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;

export const BASEURL =window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const IMG_BASEURL = BASEURL;
export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYwN2VkY2MwLTNjODQtNGRhZC1hY2VmLTBjN2FhYjNmMzU1MyIsImlhdCI6MTYwNjg5MzA0MCwiZXhwIjoxNjA5NDg1MDQwfQ.3dkWIqaDYXjJ0Eayq_nL8Q0rbmqfYcyI3NLZbcbKh8Y`;
export const MOSIC_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6ImExNjg3NjllLWViYzItNGNkMS1hYjE2LWEzOTFhNzNhYjE5NiIsImlhdCI6MTcyMjg1NjQwOH0.8z3etQzCQcMrrZ-uVS2JoDcy_llGn9cwvDBAUmH5JHY`;
export const TOKEN = MOSIC_TOKEN;
