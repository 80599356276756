import axios from 'axios';
import { BASEURL, TOKEN } from '../config';
import { TokenService, CityService, PwaService } from './tokenService';
import { reactLocalStorage } from 'reactjs-localstorage';

const createAxiosInstance = () => {
  const instance = axios.create({
    baseURL: `${BASEURL}`,
  });

  let user_token = reactLocalStorage.get('user_token', null, true);
  if (!!user_token) {
    instance.defaults.headers.common['x-user-token'] = `${user_token}`;
  }

  if (TOKEN) {
    instance.defaults.headers.common['Authorization'] = `${TOKEN}`;
  }
  // instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  const isPwa = PwaService.getIsPwa();
  if (isPwa !== null || isPwa !== undefined) {
    instance.defaults.headers.common['ISPWA'] = isPwa;
  }

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response && [401, 403].includes(error.response.status)) {
        TokenService.removeToken();
        TokenService.removeUser();
      }
      return Promise.reject(error);
    },
  );

  instance.interceptors.request.use(function (config) {
    const city_data = CityService.getCity();
    if (city_data) {
      const { cityId } = city_data;
      config.headers.CITY_ID = cityId;
    }
    return config;
  });

  return instance;
};

export default createAxiosInstance;
